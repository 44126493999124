import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Text,
  Button,
  Box,
  FormLabel,
  Textarea,
  useColorModeValue,
  FormControl,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import InputField from "views/admin/events/components/InputField";

export default function AddMenuItemDetailModal({
  isOpen,
  onClose,
  onCreateMenuItem,
}) {
  const [itemName, setItemName] = useState("");
  const [itemCategory, setItemCategory] = useState([]);
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState(null);
  const itemCategoriesOptions = ["Breakfast", "Lunch", "Dinner"];
  const [itemNameError, setItemNameError] = useState("");
  const [itemCategoryError, setItemCategoryError] = useState("");
  const [itemDescriptionError, setItemDescriptionError] = useState("");
  const [itemPriceError, setItemPriceError] = useState("");

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { fetchMenuItemData } = useContext(AppContext);

  useEffect(() => {
    setItemPrice(null);
    setItemCategory([]);
    setItemName("");
    setItemDescription("");

    setItemCategoryError("");
    setItemNameError("");
    setItemPriceError("");
    setItemDescriptionError("");
  }, [isOpen]);

  const handleItemNameChange = (e) => {
    setItemName(e.target.value);
  };

  const handleCategorySelect = (e) => {
    const newCategory = e.target.value;
    if (!selectedCategories.includes(newCategory)) {
      setSelectedCategories([...selectedCategories, newCategory]);
    }
  };

  const removeCategory = (categoryToRemove) => {
    setSelectedCategories(
      selectedCategories.filter((category) => category !== categoryToRemove)
    );
  };
  const handleItemDescriptionChange = (e) => {
    setItemDescription(e.target.value);
  };
  const handleItemPriceChange = (e) => {
    setItemPrice(e.target.value);
  };

  const handleSave = async () => {
    try {
      let isValid = true;

      if (!itemName) {
        setItemNameError("Name is required.");
        isValid = false;
      } else {
        setItemNameError("");
      }

      if (!itemDescription) {
        setItemDescriptionError("Description is required.");
        isValid = false;
      } else {
        setItemDescriptionError("");
      }

      if (!itemPrice) {
        console.log("here ????");
        setItemPriceError("Price is required.");
        isValid = false;
      } else {
        setItemPriceError("");
      }

      if (!isValid) {
        return;
      }

      onCreateMenuItem(
        itemName,
        selectedCategories,
        itemDescription,
        itemPrice
      );
      fetchMenuItemData();
      onClose();
      setItemCategoryError("");
      setItemNameError("");
      setItemPriceError("");
      setItemDescriptionError("");
    } catch (error) {
      console.error("Error updating menu item status:", error);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flex={1} textAlign="center">
          Add Menu Item
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputField
            label="Menu Item Name"
            type="text"
            value={itemName}
            onChange={handleItemNameChange}
            errorMessage={itemNameError}
            isRequired={true}
          />

          <FormControl isInvalid={!!itemDescriptionError}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              <Flex align="center">
                Item Description<Text color={brandStars}>*</Text>
              </Flex>
            </FormLabel>
            <Textarea
              isInvalid={itemDescriptionError ? true : false}
              isRequired={true}
              fontSize="sm"
              size="lg"
              value={itemDescription}
              onChange={handleItemDescriptionChange}
              mb={itemDescriptionError ? "5px" : "24px"}
            />
            {itemDescriptionError && (
              <Text
                mb="14px"
                color="red.500"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
              >
                {itemDescriptionError}
              </Text>
            )}
          </FormControl>
          <InputField
            label="Menu Item Price"
            type="number"
            value={itemPrice}
            onChange={handleItemPriceChange}
            errorMessage={itemPriceError}
            isRequired={true}
          />
          <FormControl>
            <FormLabel>Item Category</FormLabel>
            <Select
              placeholder="Select category"
              onChange={handleCategorySelect}
            >
              <option value="Starters">Starters</option>
              <option value="Bites">Bites</option>
              <option value="Wings">Wings</option>
              <option value="Salads">Salads</option>
              <option value="Sides">Sides</option>
              <option value="Fajitas and Sizzles">Fajitas and Sizzles</option>
              <option value="Pastas">Pastas</option>
              <option value="Burgers">Burgers</option>
              <option value="Sandwiches">Sandwiches</option>
              <option value="Brunch">Brunch</option>
              <option value="Lunch">Lunch</option>
              <option value="Dinner">Dinner</option>
              <option value="Desserts">Desserts</option>
            </Select>
            <Flex wrap="wrap" mt={2}>
              {selectedCategories.map((category) => (
                <Tag key={category} m={1} borderRadius="full">
                  <TagLabel>{category}</TagLabel>
                  <TagCloseButton onClick={() => removeCategory(category)} />
                </Tag>
              ))}
            </Flex>
          </FormControl>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
