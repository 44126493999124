import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

// import React from "react";
// import ReactDOM from "react-dom";
// import "assets/css/App.css";
// import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import AuthLayout from "layouts/auth";
// import AdminLayout from "layouts/admin";
// import RtlLayout from "layouts/rtl";
// import InventoryLayout from "layouts/inventory";
// import OrdersLayout from "layouts/orders";
// import returnsLayout from "layouts/returns";

// import { ChakraProvider } from "@chakra-ui/react";
// import theme from "theme/theme";
// import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
// import { AppContextProvider } from "./contexts/AppContext"; // Import AppContextProvider
// import OrderDetail from "views/admin/returns/components/OrderDetail";

// ReactDOM.render(
//   <ChakraProvider theme={theme}>
//     <React.StrictMode>
//       <ThemeEditorProvider>
//         <HashRouter>
//           <AppContextProvider>
//             <Switch>
//               <Route path={`/auth`} component={AuthLayout} />
//               <Route path={`/admin`} component={AdminLayout} />
//               <Route path={`/inventory`} component={InventoryLayout} />
//               <Route path={`/orders`} component={OrdersLayout} />
//               <Route path={`/returns`} component={returnsLayout} />
//               <Route path={`/rtl`} component={RtlLayout} />
//               <Route
//                 path="/returns/returns-default/:id"
//                 component={OrderDetail}
//               />
//               <Redirect from="/" to="/admin" />
//             </Switch>
//           </AppContextProvider>
//         </HashRouter>
//       </ThemeEditorProvider>
//     </React.StrictMode>
//   </ChakraProvider>,
//   document.getElementById("root")
// );
