import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

export default function InputField({
  label,
  value,
  onChange,
  type,
  errorMessage,
  isRequired,
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor}>
        <Flex align="center">
          {label}
          <Text color={brandStars}>*</Text>
        </Flex>
      </FormLabel>
      <Input
        isRequired={isRequired}
        fontSize="sm"
        mb={errorMessage ? "5px" : "24px"}
        size="lg"
        type={type}
        variant="auth"
        value={value}
        onChange={onChange}
        borderColor={errorMessage ? "red" : "var(--chakra-colors-gray-200)"}
      />
      {errorMessage && (
        <Text mb="14px" color="red.500" ms="4px" fontSize="sm" fontWeight="500">
          {errorMessage}
        </Text>
      )}
    </FormControl>
  );
}
