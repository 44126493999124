/* eslint-disable */
import {
  Box,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Add from "./Add";
import React, { useMemo, useState } from "react";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import EventDetailModal from "./EventDetailModal";
import { updateEvents, createEvent } from "Api/apiService";
import AddEventDetailModal from "./AddEventDetailModal";
import { getImageUrl } from "Api/apiService";
import { deleteEvents } from "Api/apiService";

export default function EventsTable(props) {
  const { columnsData, tableData } = props;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 111;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );

  const handleEventClick = (product) => {
    setSelectedEvent(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  const onUpdateEvents = async (
    eventsId,
    eventTitleName,
    eventsDescription,
    eventAsset
  ) => {
    try {
      await updateEvents(
        eventsId,
        eventTitleName,
        eventsDescription,
        eventAsset
      );
    } catch (error) {
      console.error("Error updating events:", error);
    }
  };
  const onDeleteEvent = async (eventsId) => {
    try {
      await deleteEvents(eventsId);
    } catch (error) {
      console.error("Error updating events:", error);
    }
  };
  const onCreateEvent = async (
    eventTitleName,
    eventAsset,
    eventDescription
  ) => {
    try {
      await createEvent(eventTitleName, eventAsset, eventDescription);
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="26px"
          fontWeight="700"
          lineHeight="100%"
          textAlign="center"
          flex={1}
        >
          All Events
        </Text>
        <Add onClick={() => setIsAddModalOpen(true)} />
      </Flex>
      <Box
        overflowY="auto"
        maxHeight="750px"
        css={{
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            // background: "#f1f1f1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              const event = row.original;
              return (
                <Tr
                  {...row.getRowProps()}
                  key={index}
                  onClick={() => handleEventClick(event)}
                  cursor="pointer"
                  _hover={bgHover}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Title") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Description") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Asset") {
                      data = (
                        <Image
                          h="80px"
                          w="80px"
                          src={getImageUrl(cell.value)}
                          borderRadius="8px"
                          me="20px"
                        />
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <EventDetailModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedEvent={selectedEvent}
        onUpdateEvents={onUpdateEvents}
        onDeleteEvent={onDeleteEvent}
      />
      <AddEventDetailModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        createEvent={onCreateEvent}
      />
    </Card>
  );
}
