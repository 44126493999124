/* eslint-disable */
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import MenuItemDetailsModal from "./MenuItemModal";
import { updateOrderStatus } from "Api/apiService";
import formatDate from "components/formattedTime";
import { updateMenu } from "Api/apiService";
import Add from "views/admin/events/components/Add";
import AddMenuItemDetailModal from "./AddMenuItemDetailModal";
import { createMenu } from "Api/apiService";

export default function MenuItemTable(props) {
  const { columnsData, tableData, title, setSelectedOption, options } = props;
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );

  const handleOrderClick = (order) => {
    setSelectedMenuItem(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedMenuItem(null);
    setIsModalOpen(false);
  };
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  const updateMenuItem = async (
    menuId,
    itemName,
    itemCategory,
    itemDescription,
    itemPrice
  ) => {
    try {
      await updateMenu(
        menuId,
        itemName,
        itemCategory,
        itemDescription,
        itemPrice
      );
    } catch (error) {
      console.error("Error updating menu status:", error);
    }
  };

  const renderCategory = (categories) => {
    if (Array.isArray(categories)) {
      return categories.join(", ");
    }
    return categories; // In case it's not an array, return as is
  };

  const onCreateMenuItem = async (
    itemName,
    itemCategory,
    itemDescription,
    itemPrice
  ) => {
    try {
      await createMenu(itemName, itemCategory, itemDescription, itemPrice);
    } catch (error) {
      console.error("Error creating menu:", error);
    }
  };
  return (
    <Card direction="column" w="100%" px="0px">
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="26px"
          fontWeight="700"
          lineHeight="100%"
          textAlign="center"
          flex={1}
        >
          {title}
        </Text>
        <Box display="flex" alignItems="center">
          <Add onClick={() => setIsAddModalOpen(true)} />
          <Box width="16px" height="16px" /> {/* This Box acts as a spacer */}
          <Menu setSelectedOption={setSelectedOption} options={options} />
        </Box>
      </Flex>
      <Box
        overflowY="auto"
        maxHeight="750px"
        css={{
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            // background: "#f1f1f1",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
      >
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              const order = row.original;
              return (
                <Tr
                  {...row.getRowProps()}
                  key={index}
                  onClick={() => handleOrderClick(order)}
                  cursor="pointer"
                  _hover={bgHover}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";

                    if (cell.column.Header === "NAME") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "DESCRIPTION") {
                      const formattedDate = formatDate(cell.value);
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "PRICE") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          ${cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "CATEGORY") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {renderCategory(cell.value)}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <MenuItemDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedMenuItem={selectedMenuItem}
        updateMenuItem={updateMenuItem}
      />
      <AddMenuItemDetailModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        onCreateMenuItem={onCreateMenuItem}
      />
    </Card>
  );
}
