import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Text,
  Button,
  FormLabel,
  Switch,
  useColorModeValue,
  FormControl,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import InputField from "views/admin/events/components/InputField";

export default function AddReservationModal({
  isOpen,
  onClose,
  onCreateReservationItem,
}) {
  const [itemDate, setItemDate] = useState("");
  const [isFull, setIsFull] = useState(false);

  const [itemDateError, setItemDateError] = useState("");
  const [isFullError, setIsFullError] = useState("");

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const { fetchReservationData } = useContext(AppContext);

  useEffect(() => {
    setItemDate("");
    setIsFull(false);
    setItemDateError("");
    setIsFullError("");
  }, [isOpen]);

  const handleItemDateChange = (event) => setItemDate(event.target.value);

  const handleSave = async () => {
    let isValid = true;

    if (!itemDate) {
      setItemDateError("Date is required.");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    onCreateReservationItem(itemDate);
    fetchReservationData();
    onClose();
    setItemDate("");
    setIsFull(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flex={1} textAlign="center">
          Add Reservation Full Date
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputField
            label="Date"
            type="date"
            value={itemDate}
            onChange={handleItemDateChange}
            errorMessage={itemDateError}
            isRequired={true}
            color={textColor}
          />

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50px"
            mt="40px"
            mb="30px"
            onClick={handleSave}
            isDisabled={!itemDate || isFullError}
          >
            Save
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
