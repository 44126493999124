import React, { useState, useEffect, createContext } from "react";
import { getAllEvents, getAllMenu, getToken } from "Api/apiService";
import { getAllReservation } from "Api/apiService";

export const AuthContext = createContext();
export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [eventsData, setEventsData] = useState([]);
  const [menuItemData, setMenuItemData] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const value = {
    token: getToken(),
  };

  const fetchMenuItemData = async () => {
    try {
      const menuResponse = await getAllMenu();
      if (Array.isArray(menuResponse.data.menuItem)) {
        setMenuItemData(menuResponse.data.menuItem);
      } else {
        console.error(
          "Expected menuItem to be an array, but got:",
          typeof menuResponse.data.menuItem
        );
      }
      setRefresh(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReservationData = async () => {
    try {
      const reservationResponse = await getAllReservation();
      if (Array.isArray(reservationResponse.data.reservationFull)) {
        setReservationData(reservationResponse.data.reservationFull);
      } else {
        console.error(
          "Expected reservation data to be an array, but got:",
          typeof reservationResponse.data
        );
      }
      setRefresh(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEventsData = async () => {
    try {
      const eventsResponse = await getAllEvents();
      if (Array.isArray(eventsResponse.data.event)) {
        setEventsData(eventsResponse.data.event);
      } else {
        console.error(
          "Expected event data to be an array, but got:",
          typeof eventsResponse.data.event
        );
      }
      setRefresh(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsResponse = await getAllEvents();
        const menuResponse = await getAllMenu();
        const reservationResponse = await getAllReservation();

        if (Array.isArray(reservationResponse.data.reservationFull)) {
          setReservationData(reservationResponse.data.reservationFull);
        } else {
          console.error(
            "Expected reservationFull to be an array, but got:",
            typeof reservationResponse.data.reservationFull
          );
        }

        if (Array.isArray(eventsResponse.data.event)) {
          setEventsData(eventsResponse.data.event);
        } else {
          console.error(
            "Expected event to be an array, but got:",
            typeof eventsResponse.data.event
          );
        }

        if (Array.isArray(menuResponse.data.menuItem)) {
          setMenuItemData(menuResponse.data.menuItem);
        } else {
          console.error(
            "Expected menuItem to be an array, but got:",
            typeof menuResponse.data.menuItem
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    setRefresh(false);
  }, [refresh]);

  return (
    <AuthContext.Provider value={value}>
      <AppContext.Provider
        value={{
          eventsData,
          menuItemData,
          reservationData,
          setMenuItemData,
          setReservationData,
          fetchReservationData,
          fetchMenuItemData,
          fetchEventsData,
        }}
      >
        {children}
      </AppContext.Provider>
    </AuthContext.Provider>
  );
};
