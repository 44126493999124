import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome, MdLock } from "react-icons/md";

import Profile from "views/admin/profile";
import Events from "views/admin/events";
import MenuItem from "views/admin/menuItem";
import Reservation from "views/admin/reservations";
import SignInCentered from "views/auth/signIn";

const routes = [
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Events",
    layout: "/admin",
    path: "/events",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Events,
  },
  {
    name: "Menu",
    layout: "/admin",
    path: "/menu",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MenuItem,
  },
  {
    name: "Reservation",
    layout: "/admin",
    path: "/reservation",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Reservation,
  },
];

export default routes;
