import axios from "axios";
import { jwtDecode } from "jwt-decode";

const API_URL = "https://thegrovebing.com/prod";
// const API_URL = "http://localhost:3000/prod";

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Token decode error:", error);
    return true;
  }
};

export const getToken = () => {
  return sessionStorage.getItem("token") || localStorage.getItem("token");
};

export const getAllEvents = () => {
  return axios.get(`${API_URL}/events/getAllEvents`);
};

export const getAllMenu = () => {
  return axios.get(`${API_URL}/menu/getAllMenu`);
};
export const getAllReservation = () => {
  return axios.get(`${API_URL}/reservation/getReservationFull`);
};

export const createEvent = (eventTitleName, eventDescription, eventAsset) => {
  const formData = new FormData();
  formData.append("eventTitleName", eventTitleName);
  formData.append("eventDescription", eventDescription);
  if (eventAsset) {
    console.log("eventAsset", eventAsset.name);
    formData.append("eventAsset", eventAsset, eventAsset.name);
  }

  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const rsp = axios.post(`${API_URL}/events/addEvent`, formData, config);
  console.log("rsp", rsp);
  return rsp;
};

export const updateEvents = (
  eventId,
  eventTitleName,
  eventDescription,
  eventAsset
) => {
  const formData = new FormData();
  const token = getToken();
  formData.append("eventTitleName", eventTitleName);
  formData.append("eventDescription", eventDescription);
  if (eventAsset) {
    console.log("eventAsset", eventAsset);
    formData.append("eventAsset", eventAsset, eventAsset.name);
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("formData", formData);
  return axios.put(`${API_URL}/events/${eventId}`, formData, config);
};

export const deleteEvents = (eventsId) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.delete(`${API_URL}/events/delete/${eventsId}`, config);
};

export const createMenu = (
  itemName,
  itemCategory,
  itemDescription,
  itemPrice
) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${API_URL}/menu/addMenuItem`,
    {
      itemName,
      itemCategory,
      itemDescription,
      itemPrice,
    },
    config
  );
};

export const updateMenu = (
  menuId,
  itemName,
  itemCategory,
  itemDescription,
  itemPrice
) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(
    `${API_URL}/menu/updateMenuItem/${menuId}`,
    {
      itemName,
      itemCategory,
      itemDescription,
      itemPrice,
    },
    config
  );
};

export const createReservationFull = (reservationDate) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(
    `${API_URL}/reservation/addReservationFull`,
    {
      reservationDate: reservationDate,
    },
    config
  );
};

export const updateReservationItem = (reservationId, reservationDate) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.put(
    `${API_URL}/reservation/updateReservationFull/${reservationId}`,
    {
      reservationDate,
    },
    config
  );
};
export const deleteReservation = (reservationId) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.delete(
    `${API_URL}/reservation/deleteReservationFull/${reservationId}`,
    config
  );
};
export const deleteMenu = (menuId) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.delete(`${API_URL}/menu/${menuId}`, config);
};

export async function login(email, password) {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
    });
    sessionStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    console.error("Error checking if user is logged in", error);
    throw error;
  }
}

export const logout = (history) => {
  sessionStorage.removeItem("token");
  history.push("/auth/sign-in");
};

export const getImageUrl = (relativePath) => {
  return `${API_URL}/${relativePath}`;
};
