export const columnsData = [
  {
    Header: "Title",
    accessor: "eventTitleName",
  },
  {
    Header: "Description",
    accessor: "eventDescription",
  },
  {
    Header: "Asset",
    accessor: "eventAsset",
  },
];
