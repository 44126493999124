// Chakra imports
import React, { useState, useContext, useEffect } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import MenuItemTable from "views/admin/menuItem/components/MenuItemTable";
import { columnsData } from "views/admin/menuItem/variables/columnsData";
import { AppContext } from "contexts/AppContext";

export default function Orders() {
  const options = [
    { value: "1", label: "All Menu Items" },
    // { value: "2", label: "All Processed Orders" },
    // { value: "3", label: "All Shipped Orders" },
    // { value: "4", label: "All Delivered Orders" },
    // { value: "5", label: "All Pending Orders" },
  ];
  const [selectedOption, setSelectedOption] = useState("1");

  const { menuItemData, fetchMenuItemData } = useContext(AppContext);
  useEffect(() => {
    fetchMenuItemData();
  }, []);

  const optionMappings = {
    1: { tableData: menuItemData, title: "All Menu Items" },
    // 2: { tableData: processedOrdersData, title: "All Processed Orders" },
    // 3: { tableData: shippedOrdersData, title: "All Shipped Orders" },
    // 4: { tableData: deliveredOrdersData, title: "All Delivered Orders" },
    // 5: { tableData: pendingOrdersData, title: "All Pending Orders" },
  };
  const selectedMapping = optionMappings[selectedOption];
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
        width={"100%"}
      >
        {selectedMapping && menuItemData && (
          <MenuItemTable
            columnsData={columnsData}
            tableData={selectedMapping.tableData}
            title={selectedMapping.title}
            setSelectedOption={setSelectedOption}
            options={options}
          />
        )}
      </SimpleGrid>
    </Box>
  );
}
