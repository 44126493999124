// Chakra imports
import React, { useEffect, useContext } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import EventsTable from "views/admin/events/components/EventsTable";
import { columnsData } from "views/admin/events/variables/columnsData";
import { AppContext } from "contexts/AppContext";

export default function Events() {
  // Chakra Color Mode
  const { eventsData, fetchEventsData } = useContext(AppContext);
  useEffect(() => {
    fetchEventsData();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <EventsTable columnsData={columnsData} tableData={eventsData} />
      </SimpleGrid>
    </Box>
  );
}
