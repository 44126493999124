import { isTokenExpired } from "Api/apiService";
import { getToken } from "Api/apiService";
import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useTokenValidation } from "utils/userTokenValidation";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  useTokenValidation(() => {
    history.push("/auth/sign-in");
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        const token = getToken();
        if (!token || isTokenExpired(token)) {
          return <Redirect to="/auth/sign-in" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
