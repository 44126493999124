import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Text,
  Button,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import InputField from "./InputField";
import FileUpload from "./FileUpload";

export default function EventDetailModal({
  isOpen,
  onClose,
  selectedEvent,
  onUpdateEvents,
  onDeleteEvent,
}) {
  const [eventTitleName, setEventTitleName] = useState(
    selectedEvent?.eventTitleName
  );
  const [eventDescription, setEventDescription] = useState(
    selectedEvent?.eventDescription
  );
  const [eventAsset, setEventAsset] = useState("");

  const [eventAssetError, setEventAssetError] = useState("");
  const [eventDescriptionError, setEventDescriptionError] = useState("");
  const [eventTitleNameError, setEventTitleNameError] = useState("");
  const [uploadError, setUploadError] = useState(null);
  const [fileData, setFileData] = useState(null);

  const { fetchEventsData } = useContext(AppContext);

  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const handleFileSelect = (file) => {
    setFileData(file);
    setEventAsset(file); // Store the whole file object
  };

  const handleFileRemove = () => {
    setFileData(null);
    setEventAsset("");
  };

  useEffect(() => {
    if (isOpen) {
      // Load data when modal opens
      setEventDescription(selectedEvent?.eventDescription);
      setEventTitleName(selectedEvent?.eventTitleName);
    } else {
      // Reset data and errors when modal closes
      setEventAsset("");
      setEventDescription("");
      setEventTitleName("");
      setEventAssetError("");
      setEventDescriptionError("");
      setEventTitleNameError("");
    }
  }, [isOpen, selectedEvent]);

  const handleEventDescriptionChange = (e) => {
    setEventDescription(e.target.value);
  };
  const handleEventTitleNameChange = (e) => {
    setEventTitleName(e.target.value);
  };
  const handleDelete = async () => {
    onDeleteEvent(selectedEvent._id);
    fetchEventsData();
    onClose();
  };
  const handleUpdate = async () => {
    try {
      let isValid = true;

      if (!eventTitleName) {
        setEventTitleNameError("Title is required.");
        isValid = false;
      } else {
        setEventTitleNameError("");
      }

      if (!eventDescription) {
        setEventDescriptionError("Description is required.");
        isValid = false;
      } else {
        setEventDescriptionError("");
      }

      // if (!eventAsset) {
      //   setEventAssetError("Asset is required.");
      //   isValid = false;
      // } else {
      //   setEventAssetError("");
      // }

      if (!isValid) {
        console.log("isValid", isValid);

        return;
      }
      console.log("fileData", fileData);
      onUpdateEvents(
        selectedEvent._id,
        eventTitleName,
        eventDescription,
        fileData
      );

      fetchEventsData();
      onClose();
      setEventAssetError("");
      setEventDescriptionError("");
      setEventTitleNameError("");
      setFileData(null);
    } catch (error) {
      console.error("Error updating event status:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flex={1} textAlign="center">
          Event Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputField
            label="Event Title Name"
            type="text"
            value={eventTitleName}
            onChange={handleEventTitleNameChange}
            errorMessage={eventTitleNameError}
            isRequired={true}
          />

          <FormControl isInvalid={!!eventDescriptionError}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
            >
              <Flex align="center">
                Event Description<Text color={brandStars}>*</Text>
              </Flex>
            </FormLabel>
            <Textarea
              isInvalid={eventDescriptionError ? true : false}
              isRequired={true}
              fontSize="sm"
              size="lg"
              value={eventDescription}
              onChange={handleEventDescriptionChange}
              mb={eventDescriptionError ? "5px" : "24px"}
            />
            {eventDescriptionError && (
              <Text
                mb="14px"
                color="red.500"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
              >
                {eventDescriptionError}
              </Text>
            )}
          </FormControl>
          <FileUpload
            error={uploadError}
            setError={setUploadError}
            onFileSelect={handleFileSelect}
            onFileRemove={handleFileRemove}
          />
          {eventAssetError && (
            <Text
              mb="14px"
              color="red.500"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
            >
              {eventAssetError}
            </Text>
          )}
          <div
            style={{
              display: "flex",
              gap: 20,
              justifyContent: "center",
            }}
          >
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="30%"
              h="50"
              mb="24px"
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="30%"
              h="50"
              mb="24px"
              style={{ backgroundColor: "red" }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
