import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import InputField from "./InputField";
import FileUpload from "./FileUpload";

export default function AddEventDetailModal({ isOpen, onClose, createEvent }) {
  const [eventAsset, setEventAsset] = useState("");
  const [eventTitleName, setEventTitleName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventAssetError, setEventAssetError] = useState("");
  const [eventDescriptionError, setEventDescriptionError] = useState("");
  const [eventTitleNameError, setEventTitleNameError] = useState("");
  const [uploadError, setUploadError] = useState(null);
  const [fileData, setFileData] = useState(null);

  const { fetchEventsData } = useContext(AppContext);

  useEffect(() => {
    setEventAsset("");
    setEventTitleName("");
    setEventDescription("");
    setEventAssetError("");
    setEventDescriptionError("");
    setEventTitleNameError("");
  }, [isOpen]);

  const handleFileSelect = (file) => {
    setFileData(file);
    setEventAsset(file); // Store the whole file object
  };

  const handleFileRemove = () => {
    setFileData(null);
    setEventAsset("");
  };

  const handleEventDescriptionChange = (e) => {
    setEventDescription(e.target.value);
  };
  const handleEventTitleNameChange = (e) => {
    setEventTitleName(e.target.value);
  };
  const handleEventAssetChange = (e) => {
    setEventAsset(e.target.value);
  };

  const handleSave = async () => {
    try {
      let isValid = true;

      if (!eventTitleName) {
        setEventTitleNameError("Title is required.");
        isValid = false;
      } else {
        setEventTitleNameError("");
      }

      if (!eventDescription) {
        setEventDescriptionError("Description is required.");
        isValid = false;
      } else {
        setEventDescriptionError("");
      }

      if (!eventAsset) {
        setEventAssetError("Asset is required.");
        isValid = false;
      } else {
        setEventAssetError("");
      }

      if (!isValid) {
        return;
      }
      console.log("eventTitleName", eventTitleName);
      console.log("eventDescription", eventDescription);

      createEvent(eventTitleName, eventDescription, fileData);
      fetchEventsData();
      onClose();
      setEventAssetError("");
      setEventDescriptionError("");
      setEventTitleNameError("");
      setFileData(null);
    } catch (error) {
      console.error("Error updating event status:", error);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flex={1} textAlign="center">
          Add Event
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputField
            label="Event Title Name"
            type="text"
            value={eventTitleName}
            onChange={handleEventTitleNameChange}
            errorMessage={eventTitleNameError}
            isRequired={true}
          />

          <InputField
            label="Event Description"
            type="text"
            value={eventDescription}
            onChange={handleEventDescriptionChange}
            errorMessage={eventDescriptionError}
            isRequired={true}
          />

          <FileUpload
            error={uploadError}
            setError={setUploadError}
            onFileSelect={handleFileSelect}
            onFileRemove={handleFileRemove}
          />

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
