import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Button,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  useColorModeValue,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "contexts/AppContext";
import InputField from "views/admin/events/components/InputField";

// Styled Components

export default function DeleteModal({
  isOpen,
  onClose,
  selectedReservationItem,
  onDeleteEvent,
}) {
  const { fetchReservationData } = useContext(AppContext);

  const handleDelete = async () => {
    onDeleteEvent(selectedReservationItem._id);
    fetchReservationData();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flex={1} textAlign="center">
          Delete Reservation
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            style={{ backgroundColor: "red" }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
