// Chakra imports
import React, { useState, useContext, useEffect } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import MenuItemTable from "views/admin/menuItem/components/MenuItemTable";
import { columnsData } from "views/admin/reservations/variables/columnsData";
import { AppContext } from "contexts/AppContext";
import ReservationTable from "./components/ReservationTable";

export default function Reservation() {
  const options = [{ value: "1", label: "All Reservation Full Dates" }];
  const [selectedOption, setSelectedOption] = useState("1");

  const { reservationData, fetchReservationData } = useContext(AppContext);
  useEffect(() => {
    fetchReservationData();
  }, []);
  const optionMappings = {
    1: { tableData: reservationData, title: "All Reservations Full" },
  };
  const selectedMapping = optionMappings[selectedOption];
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
        width={"100%"}
      >
        {selectedMapping && reservationData && (
          <ReservationTable
            columnsData={columnsData}
            tableData={selectedMapping.tableData}
            title={selectedMapping.title}
            setSelectedOption={setSelectedOption}
            options={options}
          />
        )}
      </SimpleGrid>
    </Box>
  );
}
