import React, { useCallback, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Icon,
  Box,
  Text,
  VStack,
  useColorModeValue,
  useToast,
  CloseButton,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { MdUpload } from "react-icons/md";

const FileUpload = ({ error, setError, onFileSelect, onFileRemove }) => {
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const brandColor = useColorModeValue("brand.500", "white");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file && file.type.substr(0, 5) === "image") {
        setSelectedFile(file);
        setPreviewSrc(URL.createObjectURL(file));
        setError(null);
        onFileSelect(file);
      } else {
        toast({
          title: "Invalid file type",
          description: "Please select an image file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setError("Please select an image file.");
      }
    },
    [setError, toast, onFileSelect]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setPreviewSrc(null);
    setError(null);
    onFileRemove();
  };

  return (
    <FormControl isInvalid={!!error}>
      <VStack
        {...getRootProps()}
        p={5}
        borderWidth="2px"
        borderRadius="md"
        borderStyle="dashed"
        mb="20px"
      >
        <input {...getInputProps()} />
        <FormLabel htmlFor="file-upload" mb="0">
          <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
        </FormLabel>
        <Text>PNG, JPG and GIF files are allowed</Text>
      </VStack>
      {selectedFile && (
        <Box mt="2" textAlign="left" position="relative" mb="20px">
          <CloseButton
            position="absolute"
            right="0"
            top="0"
            onClick={handleRemoveFile}
          />
          <Image src={previewSrc} alt="Preview" maxH="200px" />
          <Text fontSize="sm" fontWeight="500">
            {selectedFile.name}
          </Text>
        </Box>
      )}
      {error && <Text color="red.500">{error}</Text>}
    </FormControl>
  );
};

export default FileUpload;
