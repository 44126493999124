export const columnsData = [
  {
    Header: "NAME",
    accessor: "itemName",
  },
  {
    Header: "DESCRIPTION",
    accessor: "itemDescription",
  },
  {
    Header: "PRICE",
    accessor: "itemPrice",
  },
  {
    Header: "CATEGORY",
    accessor: "itemCategory",
  },
];
