import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import EventsLayout from "layouts/events";
import MenuItemLayout from "layouts/menuItem";
import ReservationLayout from "layouts/reservations";

import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import theme from "theme/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { AppContextProvider } from "./contexts/AppContext";
import PrivateRoute from "PrivateRoute";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <Router>
            <AppContextProvider>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <PrivateRoute path={`/`} component={AdminLayout} />
                <PrivateRoute path={`/events`} component={EventsLayout} />
                <PrivateRoute path={`/menu`} component={MenuItemLayout} />
                <PrivateRoute
                  path={`/reservation`}
                  component={ReservationLayout}
                />

                {/* <Redirect from="/" to="/admin" /> */}
              </Switch>
            </AppContextProvider>
          </Router>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

export default App;
