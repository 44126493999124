export const columnsData = [
  {
    Header: "DATE",
    accessor: "reservationDate",
  },
  {
    Header: "RESERVATION",
    accessor: "reservationFull",
  },
];
