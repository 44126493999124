import { useEffect } from "react";
import { isTokenExpired, getToken } from "Api/apiService";

export const useTokenValidation = (onTokenInvalid) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const token = getToken();
      if (!token || isTokenExpired(token)) {
        onTokenInvalid();
      }
    }, 3600000);

    return () => clearInterval(interval);
  }, [onTokenInvalid]);
};
